import { MoveRight } from "lucide-react";
import React from "react";
import "./App.css";
import { ListItem } from "./components/list-item";

function App() {
  const navigateInstagram = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.preventDefault();
    window.open(
      "https://www.instagram.com/karlgustav.jonsson",
      "_blank",
      "noopener,noreferrer"
    );
  };

  const sendEmail = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    window.open(
      "mailto:karl-gustav.jonsson@kalleonline.se",
      "_blank",
      "noopener,noreferrer"
    );
  };
  return (
    <div className="App">
      <div className="app-container">
        <div className="linkContainer">
          <div>
            <h1 style={{ margin: 0, padding: 0 }}>Karl-Gustav Jönsson</h1>
            <div className="subtitle">
              <p>Möbelsnickeri</p>
            </div>
          </div>
          <div className="subCategories">
            <ListItem
              title="Kontakt"
              subtitle="kalle@kalleonline.se"
              onClick={sendEmail}
            />
            <ListItem
              title="Besök"
              subtitle="Enskede gårdsväg 14D, Enskede gård"
            />
            <ListItem
              title="Portfolio"
              icon={MoveRight}
              onClick={navigateInstagram}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
