import { LucideIcon } from "lucide-react";

interface ListItemProps {
  title: string;
  subtitle?: string;
  icon?: LucideIcon;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const ListItem: React.FC<ListItemProps> = ({
  subtitle,
  title,
  icon,
  onClick,
}) => {
  const IconComp = icon;
  return (
    <div
      onClick={onClick ? onClick : undefined}
      style={{ cursor: onClick ? "pointer" : "default" }}
    >
      <h2>{title}</h2>
      {IconComp && (
        <IconComp size={24} style={{ opacity: 0.6 }} strokeWidth={1.5} />
      )}
      <div className="subtitle">{subtitle && <p>{subtitle}</p>}</div>
    </div>
  );
};
